module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"icon":"src/images/icon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"506dec4168b1d1aa6f73beff738dc0e9"},
    },{
      plugin: require('../node_modules/gatsby-plugin-matomo/gatsby-browser.js'),
      options: {"plugins":[],"siteId":"20","matomoUrl":"https://analytics.list.lu","matomoPhpScript":"rainbow","matomoJsScript":"unicorn","siteUrl":"https://ai-sandbox.list.lu","requireCookieConsent":true,"cookieDomain":"ai-sandbox.list.lu","dev":false},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
